<template>
  <v-container>
    <v-layout justify-center >
      <v-card width="1200px" height="auto" class="mx-auto ml-0">
        <!-- 시 간격별 데이타 조회   -->
        <v-data-table  dense height="550"
          v-show="divisionTerm < 2"
          fixed-header
          :footer-props="{
                showFirstLastPage: true,
                disableItemsPerPage: true,
                itemsPerPageOptions: [300],
              }"
          :loading="loadingTable" 
          :headers="headers"
          :items="items"
          no-data-text="측정된 자료가 없습니다."
          class="elevation-3"
          item-key="checkDateTime"
          single-select
          :sort-by= "['checkDate','checkTime']"
          :sort-desc= "[true,true]"
          :search="search"

        >
        </v-data-table>

        <!-- 일별 데이타 조회   -->
        <v-data-table  dense height="550" width="1200px"
          v-show="divisionTerm > 1"
          fixed-header
          hide-default-footer
          :items-per-page= "1000"
          :loading="loadingTable" 
          :headers="headers_daily"
          :items="items_daily"
          no-data-text="측정된 자료가 없습니다."
          class="elevation-3"
          single-select
          :sort-desc= "true"
          :search="search"
          hide-default-header
          >
          <template v-slot:header="{ props }">
            <tr style="background-color: #3a4f3f;" align="left" >
            <th :style="head.lb ? 'border-left: 1px solid white;' : '' " v-for="(head, i) in props.headers"
                :key="i">
                <td class="cell_top" nowrap > {{ head.text0 }} </td>
            </th>
            </tr>
            <tr style="background-color: #3a4f3f;" align="center"  >
            <th :style="head.lb ? 'border-left: 1px solid white;' : '' " v-for="(head, i) in props.headers"
                :key="i">
                <td class="cell_bottom">  {{ head.text }} </td>
            </th>
            </tr>
          </template>
          <template v-slot:item= "{ item }">
            <tr>
              <td>{{ item.checkDate }}</td>
              <td class="cell_center">{{ item.watt_highest }}</td>
              <td >{{ item.watt_lowest }}</td>
              <td class="cell_watt" >{{ item.watt_sum}}</td>

              <td >{{ item.w_temp_highest }}</td>
              <td class="cell_right">{{ item.w_temp_lowest }}</td>
              <td class="cell_temp">{{ item.w_temp_average }}</td>

              <td class="cell_right">{{ item.w_hum_highest }}</td>
              <td class="cell_right">{{ item.w_hum_lowest }}</td>
              <td class="cell_hum">{{ item.w_hum_average }}</td>

              <td >{{ item.w_ps_highest }}</td>
              <td >{{ item.w_ps_lowest }}</td>
              <td class="cell_pres">{{ item.w_ps_average }}</td>

              <td class="cell_right">{{ item.w_ws_highest }}</td>
              <td class="cell_right">{{ item.w_ws_lowest }}</td>
              <td class="cell_wind">{{ item.w_ws_average }}</td>

            </tr>
          </template>
        </v-data-table>

    
        <v-card-title>
          <v-text-field dense style="width:300px" class="shrink"
            clearable
            v-model="search"
            append-icon="mdi-magnify"
            label="날짜 또는 시간으로 골라보기"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
// import Apis from '@/api/apis'
// import Common from "@/utils/custom/common.js";

export default {
  name: 'WattList',
  props: {
    items: {
      type: Array,
    },
    items_daily: {
      type: Array,
    },
    loadingTable: Boolean,
    divisionTerm: Number,
  },

  created() {
  },
  
  data() {
    return {
      search: "",
      headers: [
        { text: '측정일', align: 'left', value: 'checkDate',filterable: true, sortable: true, groupable: false, width: "110px", class: 'green darken-4 white--text',},
        { text: '측정시간', align: 'left', value: 'checkTime', filterable: true, sortable: true, groupable: false, width: "100px", class: 'green darken-4 white--text',divider: true },
        { text: '사용전력량 kw', align: 'center', value: 'watt_sum', filterable: false, sortable: false, groupable: false, width: "110px", class: 'green darken-4 white--text',divider: true },
        
        { text: '기온 ℃', align: 'center', value: 'w_temp', filterable: false, sortable: false, groupable: false, width: "110px", class: 'green darken-4 white--text',divider: true },
        { text: '습도 %', align: 'center', value: 'w_hum', filterable: false, sortable: false, groupable: false, width: "110px", class: 'green darken-4 white--text',divider: true },
        { text: '기압 hPa', align: 'center', value: 'w_ps', filterable: false, sortable: false, groupable: false, width: "110px", class: 'green darken-4 white--text',divider: true },
        { text: '풍속 m/s', align: 'center', value: 'w_ws', filterable: false, sortable: false, groupable: false, width: "110px", class: 'green darken-4 white--text',divider: true },
      ],

      headers_daily: [
        { text: '측정일', align: 'left', value: 'checkDate', filterable: true, sortable: false, groupable: false, width: "170px", class: 'green darken-4 white--text', divider: true },
        { lb: true, text0: '사용전력량 kw',text: '최고', align: 'end', value: 'watt_highest', filterable: false, sortable: false, groupable: false, width: "60px", class: 'green darken-4 white--text', divider: false },
        { text0: '',text: '최저', align: 'end', value: 'watt_lowest', filterable: false, sortable: false, groupable: false, width: "60px", class: 'green darken-4 white--text', divider: false },
        { text: '합계', align: 'end', value: 'watt_sum', filterable: false, sortable: false, groupable: false, width: "60px", class: 'green darken-4 white--text',divider: true },

        { lb: true, text0: '기온 ℃',text: '최고', align: 'end', value: 'w_temp_highest', filterable: false, sortable: false, groupable: false, width: "60px", class: 'green darken-4 white--text', divider: false },
        { text0: '',text: '최저', align: 'end', value: 'w_temp_lowest', filterable: false, sortable: false, groupable: false, width: "60px", class: 'green darken-4 white--text', divider: false },
        { text: '평균', align: 'end', value: 'w_temp_average', filterable: false, sortable: false, groupable: false, width: "60px", class: 'green darken-4 white--text',divider: true },

        { lb: true, text0: '습도 %',text: '최고', align: 'end', value: 'w_hum_highest', filterable: false, sortable: false, groupable: false, width: "60px", class: 'green darken-4 white--text',divider: false },
        { text0: '',text: '최저', align: 'end', value: 'w_hum_lowest', filterable: false, sortable: false, groupable: false, width: "60px", class: 'green darken-4 white--text',divider: false },
        { text: '평균', align: 'end', value: 'w_hum_average', filterable: false, sortable: false, groupable: false, width: "60px", class: 'green darken-4 white--text',divider: true },

        { lb: true, text0: '기압 hPa',text: '최고', align: 'end', value: 'w_ps_highest', filterable: false, sortable: false, groupable: false, width: "60px", class: 'green darken-4 white--text',divider: false },
        { text0: '',text: '최저', align: 'end', value: 'w_ps_lowest', filterable: false, sortable: false, groupable: false, width: "60px", class: 'green darken-4 white--text',divider: false },
        { text: '평균', align: 'end', value: 'w_ps_average', filterable: false, sortable: false, groupable: false, width: "60px", class: 'green darken-4 white--text',divider: true },

        { lb: true, text0: '풍속 m/s',text: '최고', align: 'end', value: 'w_ws_highest', filterable: false, sortable: false, groupable: false, width: "60px", class: 'green darken-4 white--text',divider: false },
        { text0: '',text: '최저', align: 'end', value: 'w_ws_lowest', filterable: false, sortable: false, groupable: false, width: "60px", class: 'green darken-4 white--text',divider: false },
        { text: '평균', align: 'end', value: 'w_ws_average', filterable: false, sortable: false, groupable: false, width: "60px", class: 'green darken-4 white--text',divider: true },
      
      ],
    }
  },
  methods: {
  },  // -----------methods 
}
</script>

<style lang="scss" scoped>
.v-data-table::v-deep{
  thead.v-data-table-header tr {
    th {
      background-color: #436251 !important;
      color: white !important;
      font-size:13px;
      height:40px !important;
    }
  }
}
.cell_top{
  // text-align:right;
  // font-weight: bold;
  padding-left: 5px;
  font-size: 13px;
  color: white;
  background-color: #3a4f3f;
}
.cell_bottom{
  // text-align:right !important;
  // font-weight: bold;
  font-size: 13px;
  color: white !important;
  background-color: #3a4f3f;
  }
.cell_right{
  text-align:right;
}
.cell_center{
  text-align:center;
}
.cell_watt{
  font-weight: bold;
  text-align:right;
  background-color: #F1FCF3;
}
.cell_temp{
  font-weight: bold;
  text-align:right;
  font-weight: bold;
  background-color: #E8F3F5;
}
.cell_hum{
  // color:red;
  font-weight: bold;
  text-align:right;
  background-color: #F2FCF4;
}
.cell_pres{
  font-weight: bold !important;
  // color:red;
  text-align: right;
  background-color: #EAECDF;
}
.cell_wind{
  font-weight: bold;
  // color:red;
  text-align:center;
  background-color: #fffddd;
}

.cell_h2s{
  font-weight: bold;
  // color:red;
  text-align:center;
  background-color: #ddfff1;
}
.cell_r{
  // color:red;
  text-align:right;
}
.cell_red{
  // color:red;
  color: white;
  text-align:right;
  background-color: rgb(253, 75, 52);
}
</style>

