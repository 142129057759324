<template>
<v-container>
    <div class="chart_Layout"
    v-if="trendData.temp && trendData.temp.length > 1">
        <div class="mb-1"> 
            <div class="y_content">
                <span class="title temp">기온</span>
                <span class="title watt">전력량</span>
            </div>
        </div>
        <Bar
            id="tempChart"
            ref="tempChart"
            :chart-options="multiOption"
            :chart-data="chartTemp"
            :height="200" 
            :width="1200"
        />
    </div>

    <div class="chart_Layout"
        v-if="trendData.hum && trendData.hum.length > 1">
        <div class="mb-1"> 
            <div class="y_content">
                <span class="title hum">습도</span>
                <span class="title watt">전력량</span>
            </div>
        </div>
        <Bar
            id="humChart"
            ref="humChart"
            :chart-options="multiOption"
            :chart-data="chartHum"
            :height="200" 
        />
    </div>

    <div class="chart_Layout"
        v-if="trendData.pres && trendData.pres.length > 1">
        <div class="mb-1"> 
            <div class="y_content">
                <span class="title pres">기압</span>
                <span class="title watt">전력량</span>
            </div>

        </div>
        <Bar
            id="presChart"
            ref="presChart"
            :chart-options="multiOption"
            :chart-data="chartPres"
            :height="200" 
        />
    </div>

    <div class="chart_Layout"
        v-if="trendData.wind && trendData.wind.length > 1">
        <div class="mb-1"> 
            <div class="y_content">
                <span class="title wind">풍속</span>
                <span class="title watt">전력량</span>
            </div>
        </div>
        <Bar
            id="windChart"
            ref="windChart"
            :chart-options="multiOption"
            :chart-data="chartWind"
            :height="200" 
            :width="1200"
        />
    </div> 

    <!-- 일별-->
    <div class="chart_Layout"
        v-if="trendData.temp_avg && trendData.temp_avg.length > 1">
        <div class="y_content">
            <span class="title temp">기온</span>
            <span class="title watt">전력량</span>
        </div>
        <LineChartGenerator
            :chart-options="Dailyoptions"
            :chart-data="dailyChartTemp"
            :height="200"
            :width="1200"
        />
    </div>
    <div class="chart_Layout"
        v-if="trendData.hum_avg && trendData.hum_avg.length > 1">
        <div class="y_content">
            <span class="title temp">습도</span>
            <span class="title watt">전력량</span>
        </div>
        <LineChartGenerator
            :chart-options="Dailyoptions"
            :chart-data="dailyChartHum"
            :height="200"
            :width="1200"
        />
    </div>

    <div class="chart_Layout"
        v-if="trendData.pres_avg && trendData.pres_avg.length> 1">
        <div class="y_content">
            <span class="title temp">기압</span>
            <span class="title watt">전력량</span>
        </div>
        <LineChartGenerator
            :chart-options="Dailyoptions"
            :chart-data="dailyChartPres"
            :height="200"
            :width="1200"
        />
    </div>

    <div class="chart_Layout"
        v-if="trendData.wind_avg && trendData.pres_avg.length> 1">
        <div class="y_content">
            <span class="title temp">풍속</span>
            <span class="title watt">전력량</span>
        </div>
        <LineChartGenerator
            :chart-options="Dailyoptions"
            :chart-data="dailyChartWind"
            :height="200"
            :width="1200"
        />
    </div> 
</v-container>
</template>

<script>
import { Line as LineChartGenerator } from 'vue-chartjs/legacy'
import { Bar } from 'vue-chartjs/legacy'
// import zoomPlugin from 'chartjs-plugin-zoom';
import { Chart, LineController, Tooltip, LineElement, PointElement, LinearScale, Title, CategoryScale, BarElement } from 'chart.js';

// Chart.js의 구성 요소를 등록합니다.
Chart.register(LineController, Tooltip, LineElement, PointElement, LinearScale, Title, CategoryScale, BarElement);

export default {
name: 'LineChart2',

components: {
    LineChartGenerator,
    Bar
},

props: {
    trendData: {
    type: Object,
    },
},

data: () => ({
    chartSize: {
        height: '200px',
        width: '1200px',
        position: 'relative',
    },

    chartTemp: { },
    chartHum: { },
    chartPres: { },
    chartWind: { },

    // dailyChartWatt: { },
    dailyChartTemp: { },
    dailyChartHum: { },
    dailyChartPres: { },
    dailyChartWind: { },
    
    multiOption:{
        scales: {
            x: {
                grid: {
                    drawOnChartArea: false // 이 축의 그리드 라인은 숨기기
                },
                ticks: {
                    // 보이는 갯수 제한하기 autoSkip, maxTrickLimit 
                    autoSkip: true,
                    maxTicksLimit:5,
                    maxRotation: 0,
                    minRotation: 0,
                    display: true,
                    padding: 3,
                    font: {
                        size: 9,
                    }
                }
            },
            'y-axis-1': {
                type: 'linear',
                position: 'left',
                ticks: {
                    beginAtZero: true,
                    suggestedMax: 100,
                },
                afterFit: function(scale) {
                    scale.width = 40; // Set the desired width
                },
                title: {
                    display: true,
                }
            },
            'y-axis-2': {
                type: 'linear',
                position: 'right',
                ticks: {
                    beginAtZero: true,
                    suggestedMax: 40
                },
                title: {
                    display: true,
                },
                grid: {
                    drawOnChartArea: false // 이 축의 그리드 라인은 숨기기
                },
                afterFit: function(scale) {
                    scale.width = 40; // Set the desired width
                }
            }
        },
        interaction: {
            mode: 'nearest',
            axis: 'x',
            intersect: false
        },
        elements: {
            line: {	/* 선 옵션 */
                    borderCapStyle: 'round',
                    borderDashOffset: 0,
                    borderJoinStyle: 'round',	
                    borderWidth: 3,
                    capBezierPoints: true,
                    // fill: true,
                    tension: 0.4
                },
                point: {	/* 표시자 옵션 */
                    pointStyle: 'rectRounded',
                    pointRadius: 0, 
                    lineTension:0, 
                    pointBorderWidth: 0, 
                },
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
              display: false,
            },
            tooltip: {
                y: [{
                backgroundColor: '#ACB8C0',
                caretSize: 4,
                cornerRadius: 6,
                borderWidth: 0,
                bodyFont: {
                    size: 12
                }
                }]
            },
            hover: {
                animationDuration: 400,
                axis: 'x',
                intersect: true,
                mode: 'label',
                onHover: null
            },
        },
    },

    Dailyoptions: {
        elements: {
            bar:{
                borderWidth:1.0
            },
            line: {
                borderCapStyle: 'round',	/* 선 마감: butt, round, square */
                borderDashOffset: 4,
                borderJoinStyle: 'round',	/* 꺾이는 부분 모양: miter, bevel, round */
                borderWidth: 2.5,
                capBezierPoints: true,
                tension: 0.4
            },
            point: {
                pointStyle: 'circle',
                pointRadius: 2, //point 없게 만들기 0으로 설정
                pointBorderWidth: 1, //포인터 보더사이즈
            },
        },
        plugins: {
            legend: {
                display: false,
                fullSize: false,
            },
            tooltip: {
                backgroundColor: '#ACB8C0',
                caretSize: 4,
                cornerRadius: 6,
                borderWidth: 0,
                bodyFont: {
                        size: 12
                }
            },
            hover: {
                animationDuration: 400,
                axis: 'x',
                intersect: true,
                mode: 'label',
                onHover: null
            },
        },
        scales: {
            x: {
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 5,
                    maxRotation: 0,
                    minRotation: 0,
                    padding: 3,
                    font: {
                        size: 9,
                    }
                }
            },
            'y-axis-1': {
                type: 'linear',
                position: 'left',
                ticks: {
                    beginAtZero: true,
                    suggestedMax: 300
                },
                title: {
                    display: true,
                }
            },
            'y-axis-2': {
                type: 'linear',
                position: 'right',
                ticks: {
                    beginAtZero: true,
                    suggestedMax: 40
                },
                title: {
                    display: true,
                },
                grid: {
                    drawOnChartArea: false // 이 축의 그리드 라인은 숨기기
                }
            }
        },
        responsive: true,
        maintainAspectRatio: false,
        animation: {
            duration: 1000
        }
    },
}),
watch:{
    trendData: function(){
        // 시간별
        this.chartTemp= this.timeChart(
            this.trendData.temp_time,
            this.trendData.temp,
            '#B9D7EA',
        );

        this.chartHum= this.timeChart(
            this.trendData.hum_time,
            this.trendData.hum,
            '#A0C7B5',
        );

        this.chartHum= this.timeChart(
            this.trendData.hum_time,
            this.trendData.hum,
            '#A0C7B5',
        );

        this.chartPres= this.timeChart(
            this.trendData.pres_time,
            this.trendData.pres,
            '#a8d9f6',
        );

        this.chartWind= this.timeChart(
            this.trendData.wind_time,
            this.trendData.wind,
            '#1a659e',
        );

         // // 일별
        this.dailyChartTemp = this.dailyChart(
            this.trendData.temp_avg,
            '#B9D7EA',
            this.trendData.temp_low,
            this.trendData.temp_high,
        );

        this.dailyChartHum = this.dailyChart(
            this.trendData.hum_avg,
            '#A0C7B5',
            this.trendData.hum_low,
            this.trendData.hum_high,
        );

        this.dailyChartPres = this.dailyChart(
            this.trendData.pres_avg,
            '#a8d9f6',
            this.trendData.pres_low,
            this.trendData.pres_high,
        );

        this.dailyChartWind = this.dailyChart(
            this.trendData.wind_avg,
            '#1a659e',
            this.trendData.wind_low,
            this.trendData.wind_high,
        );
    }
},
// mounted () {
// },
methods:{
    timeChart(dailylabel, avgData, avgColor){
        return{
            labels: dailylabel,
            datasets: [
                {
                    type:'line',
                    label: '',
                    data: avgData,
                    yAxisID: 'y-axis-1',
                    borderColor: avgColor,
                },{
                    type:'bar',
                    label:'전력량',
                    data: this.trendData.watt,
                    yAxisID: 'y-axis-2',
                    backgroundColor: "#F1F0E8",
                    barPercentage: 0.99,
                    borderRadius: 8
                }
            ]
        }
    },
    dailyChart(avgData,avgColor,lowData,highData){
        return{
            labels: this.trendData.checkDate,
            datasets:[
                {
                    data:avgData,
                    borderColor:avgColor
                },
                {
                    label:'최저',
                    data: lowData,
                    fill: false,
                    borderColor: "rgba(93, 138, 168, 0.4)",
                    tension: 0.1,
                    borderDash: [3, 3],
                },
                {
                    label:'최고',
                    data: highData,
                    fill: false,
                    borderColor:"rgba(124, 9, 2, 0.4)",
                    tension: 0.1,
                    borderDash: [3, 3],
                },
                {
                    type:'bar',
                    label:'전력량',
                    data: this.trendData.watt_sum,
                    yAxisID: 'y-axis-2',
                    backgroundColor: "#F1F0E8",
                    barPercentage: 0.4,
                    borderRadius: 20,
                }
            ]
        }
    }
    // resetGraph() {
    //     if(this.chartWatt){
    //         this.$refs.wattChart.getCurrentChart().resetZoom();
    //     }
    //     if(this.chartTemp){
    //         this.$refs.tempChart.getCurrentChart().resetZoom();
    //     }
    //     if(this.chartHum){
    //         this.$refs.humChart.getCurrentChart().resetZoom();
    //     }
    //     if(this.chartPres){
    //         this.$refs.presChart.getCurrentChart().resetZoom();
    //     }
    //     if(this.chartWind){
    //         this.$refs.windChart.getCurrentChart().resetZoom();
    //     }
    // },
},
}
</script>
    
<style lang="scss" scoped >
.y_content{
    display:flex;
    justify-content: space-between;
    padding-left:5px;
    padding-right:10px;
}
.chart_Layout{
    height: 260px !important; 
    width:100%;
    padding:10px;
    margin-top:20px;
    border:1px solid #e5e5e5;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
    /* background-color:#ffffff; */
    border-radius: 20px;
}
.title{
text-align:center;
font-weight:600 !important;
font-size:13px !important;
letter-spacing: -1px !important;
width:60px;
border-radius: 10px;
padding:0 5px;
color:#3a4f3f;
background-color:#F1F0E8;
}
.reset_btn{
background-color:#3a4f5f;
border-radius: 50%;
width:25px;
height:25px;
line-height: 15px;
box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
</style>