<template>
  <v-container text-xs-center fluid>
    <v-layout wrap justify-center >
    <v-card rounded outlined color="transparent" width="1000px" class="pa-1 mb-1">
    <v-form>
      <v-layout wrap class="mb-2">
        <v-select dense outlined rounded class="selectbox" 
          id="company" 
          ref="company" 
          label="경영체" 
          v-model="company"
          :items="companyItems"
          :menu-props="{ top: false, offsetY: true }"
          no-data-text="자료(권한)이 없습니다.."
          item-text="name"
          return-object
          @change="changeCompany()"
          @focus="comboCompanies()"
          clearable
          >
          <template v-slot:item="slotProps" >
            {{slotProps.item.name}}<span class="ml-1 items">{{slotProps.item.code}}</span>
          </template>
        </v-select>
        <v-select dense outlined rounded class="selectbox"
          id="farm" 
          ref="farm" 
          label="사업장" 
          v-model="farm"
          :items="farmItems"
          :menu-props="{ top: false, offsetY: true }"
          no-data-text="자료(권한)이 없습니다."
          item-text="name"
          return-object
          @change="changeFarm()"
          @focus="comboFarms()"
          clearable
          >
          <!-- <template slot="selection" slot-scope="data">
                {{ data.item.name }}<span class="ml-1 items">{{data.item.code}}</span>
          </template> -->
          <template v-slot:item="slotProps" >
                 {{slotProps.item.name}}<span class="ml-1 items">{{slotProps.item.code}}</span>
          </template>
        </v-select>
        <v-select dense outlined  rounded class="selectbox" 
          id="building" 
          ref="building" 
          label="건물" 
          v-model="building"
          :items="buildingItems"
          :menu-props="{ top: false, offsetY: true }"
          no-data-text="자료(권한)이 없습니다."
          placeholder="미선택 시 사업장전체를 모니터합니다"
          item-text="name"
          return-object
          @change="changeBuilding()"
          @focus="comboBuildings()"
          clearable
          >
          <!-- <template slot="selection" slot-scope="data">
                {{ data.item.name }}<span class="ml-1 items">{{data.item.code}}</span>
          </template> -->
          <template v-slot:item="slotProps" >
                 {{slotProps.item.name}}<span class="ml-1 items">{{slotProps.item.code}}</span>
          </template>
        </v-select>
      </v-layout>
      
      <v-layout wrap >
        <v-sheet min-width="330px">
          <v-slider 
            label="간격"
            color="#3a4f3f"
            track-color="gray"
            v-model="term"
            :tick-labels="['15분','1시간','일별', '월별']"
            :max="3"
            step="1"
            ticks="always"
            tick-size="5"
            @change="refreshList()"
          ></v-slider>
        </v-sheet>
        <v-spacer></v-spacer>

        <v-layout class="change_btn">
        <v-btn
          :disabled = "showList"
          class="ml-9 ma-2 show__btn"
          @click="showList = true; refreshList()"
        >
          목록
          <v-icon right dark>mdi-view-list</v-icon>
        </v-btn>
        <v-btn
          :disabled = "!showList"
          class="ma-2 show__btn"
          @click="showList = false; refreshList()"
        >
          차트
          <v-icon right dark>mdi-chart-line</v-icon>
        </v-btn>
        </v-layout>
        <v-spacer></v-spacer>

        <v-menu
          v-model="menu1"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field dense outlined style="height:40px; width:140px" class="shrink rounded mt-1 ml-1 mb-1 calendar"
              id="fr_date" 
              ref="fr_date" 
              v-model="fr_date"
              label="부터"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            no-title locale="ko-KR"
            v-model="fr_date"
            @input="menu1 = false"
          ></v-date-picker>
        </v-menu>
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field dense outlined style="height:40px; width:140px" class="shrink rounded mt-1 ml-2  mb-1 mr-1 calendar"
              id="to_date" 
              ref="to_date" 
              v-model="to_date"
              label="까지"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            no-title locale="ko-KR"
            v-model="to_date"
            @input="menu2 = false"
          ></v-date-picker>
        </v-menu>
        <v-btn class="elevation-5 ma-1 rounded normal-btn " style="height: 40px; width: 40px"
          id="refreshList" 
          :loading="loading" 
          ref="refreshList" 
          type="submit" 
          @click.prevent="refreshList()">
          <img src="@/assets/icon_search.svg" alt="새로고침" />
        </v-btn>
      </v-layout>
    </v-form>
    </v-card>
    </v-layout>
    
    <v-layout justify-center >
      <v-card outlined color="transparent" >
        <WattList :items="listData" :items_daily="listData_daily" :loadingTable="loading" :divisionTerm= "term" v-show="showList"  />
        <WattChart2 :trendData="trendData" v-show="!showList" />
      </v-card>
    </v-layout>
 </v-container>
</template>

<script>
import Apis from '@/api/apis'
import VueCookies from "vue-cookies";
import dateUtil from "@/utils/custom/dateProperty.js";
import WattList from "./child/WattList.vue";
// import WattChart from "./child/WattChart.vue";
import WattChart2 from "./child/WattChart2.vue";


export default {
  name: 'Barometer',
  components: {
    WattList,
    // WattChart
    WattChart2
  },
  async created() {
    Apis.pageCount({
      page_name: this.$route.name,
      url: this.$route.path,
      division: "pigro",
    },() => {  // 정상처리
      // console.log("pageCount 호출",res);
    }).catch( (err) => {  // 개별 API 오류 처리 예제
      console.log("pageCount 호출 오류",err);
    });
    await this.comboCompanies();
    await this.comboFarms();
    await this.comboBuildings();

    let cdate = new Date();
    this.to_date= dateUtil.format(cdate, "yyyy-MM-dd");
    let frdate = new Date(cdate.setDate(cdate.getDate() - 6));
    this.fr_date= dateUtil.format(frdate, "yyyy-MM-dd");

    let cookie_info = VueCookies.get("watt_info");
    if (cookie_info) {
      this.showList = cookie_info.showList;
      this.term = 1;
    }
    if (!this.isNull(this.$route.params)) {
      // console.log("Params : ", this.$route.params);
      this.company = this.$route.params.company;
      this.farm = this.$route.params.farm;
      this.building = this.$route.params.building;
    } else if (cookie_info) {
      this.company = cookie_info["company"] || {};
      this.farm = cookie_info.farm || {};
      this.building = cookie_info.building || {};
    } else {
      this.room = this.roomItems[0];
      this.company = {code: this.farm.company_cd, name: this.farm.company_name};
      this.farm = {code: this.farm.farm_cd, name: this.farm.farm_name};
      this.building = {code: this.building.building_cd, name: this.building.building_name};
    }
    this.refreshList();
  },
  data () {
    return {

      showList: false,
      // tabs: null,

      loading: false,
      company : {},
      companyItems : [],
      farm : {},
      farmItems : [],
      building : {},
      buildingItems:[],

      term: 1,
      fr_date: "",
      to_date: "",

      menu1: false,
      menu2: false,

      listData: [],
      listData_daily: [],

      trendData: {},
      
    }
  },
  methods: {

    refreshList() {
      if  (!this.farm || !this.farm.code ) {
        alert("사업장을 선택하세요");
        this.$refs.farm.focus();
        return;
      }
      if  (!this.fr_date) {
        alert("조회 시작일 선택하세요");
        this.$refs.fr_date.focus();
        return;
      }
      if  (!this.to_date) {
        alert("조회 시작일 선택하세요");
        this.$refs.to_date.focus();
        return;
      }
      this.$store.commit("resMessage","");
      this.loading = true;


      this.listData = [];
      this.listData_daily = [];

      if (this.term < 2) {   // 시간별
        Apis.listTimelyKepcoData({ 
          company_cd: this.company.code,
          farm_cd:  this.farm.code,
          fr_dt: this.fr_date,
          to_dt: this.to_date,
          start_time: "00:00:00",
          end_time: "23:59:59",
          timeDivision: this.term === 0 ? 15 : 60,
          },(res) => {  
            if (res.result) {
              this.listData = res.data.datalist;

              this.listData.forEach(element => {
                  if (element.watt_sum) element.watt_sum = Number(element.watt_sum).toFixed(2);
                  if (element.w_hum) element.w_hum = Number(element.w_hum).toFixed(0);
                  if (element.w_ps) element.w_ps = Number(element.w_ps).toFixed(0);
                  if (element.w_ws) element.w_ws = Number(element.w_ws).toFixed(1);
                  if (element.w_temp) element.w_temp = Number(element.w_temp).toFixed(1);
                });

              // console.log("resdata",res.data.datalist);

              if (!this.showList) {
                // 챠트 데이타
                // console.log(this.trendData)
                // console.log(this.trendData.watt)
                // console.log("trendData",this.trendData.temp.length);

                let watt_time = [];
                let watt_array = [];

                let temp_time = [];
                let temp_array = [];

                let hum_time = [];
                let hum_array = [];

                let pres_time = [];
                let pres_array = [];

                let wind_time = [];
                let wind_array = [];

                this.listData.forEach(function (item) {
                  if  (item.watt_sum && !isNaN(item.watt_sum)) {
                    watt_time.push(item.checkDateTime.substring(5,16));
                    watt_array.push( Number(item.watt_sum));

                    item.w_temp && !isNaN(item.w_temp) && temp_time.push(item.checkDateTime.substring(5,16)) || temp_time.push(item.checkDateTime.substring(5,16));
                    item.w_temp && !isNaN(item.w_temp) && temp_array.push(Number(item.w_temp)) || temp_array.push(null);

                    item.w_hum && !isNaN(item.w_hum) && hum_time.push(item.checkDateTime.substring(5,16)) || hum_time.push(item.checkDateTime.substring(5,16));
                    item.w_hum && !isNaN(item.w_hum) && hum_array.push(Number(item.w_hum)) || hum_array.push(null);

                    item.w_ps && !isNaN(item.w_ps) && pres_time.push(item.checkDateTime.substring(5,16)) || pres_time.push(item.checkDateTime.substring(5,16));
                    item.w_ps && !isNaN(item.w_ps) && pres_array.push(Number(item.w_ps)) || pres_array.push(null);

                    item.w_ws && !isNaN(item.w_ws) && wind_time.push(item.checkDateTime.substring(5,16)) || wind_time.push(item.checkDateTime.substring(5,16));
                    item.w_ws && !isNaN(item.w_ws) && wind_array.push(Number(item.w_ws)) || wind_array.push(null);
                  }
                })  
                
                // console.log("temp_array",temp_array.length);
                this.trendData = {
                  watt_time : watt_time,
                  watt: watt_array,

                  temp_time : temp_time,
                  temp: temp_array,
                  
                  hum_time: hum_time,
                  hum: hum_array,

                  pres_time: pres_time,
                  pres: pres_array,

                  wind_time: wind_time,
                  wind: wind_array,
                }

                // console.log(this.trendData1.pres)

              }
          
              this.$store.commit("resMessage",res.message);
              let cookie_info = { company: this.company, farm: this.farm, showList: this.showList, term: this.term, building:this.building };
              VueCookies.set("watt_info" ,cookie_info,"30d");
            } else {
              alert(res.message);
            }
            this.loading = false;

            // this.building && this.building.code || this.farm.code ? this.$refs.building.focus() : this.$refs.farm.focus();
            // this.$refs.refreshList.focus();

          }).catch( (err) => {  // API 오류 처리
              console.log("listTimelyKepcoData API 호출 오류",err)
              alert(err);
              this.loading = false;
          }
        )
        } else{
          Apis.kepcoDataMonthlyView({
            farm_cd: this.farm.code,
            fr_date: this.fr_date,
            to_date: this.to_date,
            "term" : this.term,
            "number_row" : 13
            },(res) => {  
              if (res.result) {
                this.listData_daily = res.data;

                if(!this.showList){
                  let checkDate = [];

                  let watt_sum = [];
                  let watt_low = [];
                  let watt_high = [];

                  let temp_avg = [];
                  let temp_low = [];
                  let temp_high = [];

                  let hum_avg = [];
                  let hum_low = [];
                  let hum_high = [];

                  let pres_avg = [];
                  let pres_low = [];
                  let pres_high = [];

                  let wind_avg = [];
                  let wind_low = [];
                  let wind_high = [];

                  res.data.forEach(function(item){
                    if(!isNaN(item.watt_sum)){
                      checkDate.push(item.checkDate.substring(5));

                      !isNaN(item.watt_sum) && watt_sum.push(Number(item.watt_sum)) || watt_sum.push(null);
                      !isNaN(item.watt_lowest) && watt_low.push(Number(item.watt_lowest)) || watt_low.push(null);
                      !isNaN(item.watt_highest) && watt_high.push(Number(item.watt_highest)) || watt_high.push(null);

                      !isNaN(item.w_temp_average) && temp_avg.push(Number(item.w_temp_average)) || temp_avg.push(null);
                      !isNaN(item.w_temp_lowest) && temp_low.push(Number(item.w_temp_lowest)) || temp_low.push(null);
                      !isNaN(item.w_temp_highest) && temp_high.push(Number(item.w_temp_highest)) || temp_high.push(null);

                      !isNaN(item.w_hum_average) && hum_avg.push(Number(item.w_hum_average)) || hum_avg.push(null);
                      !isNaN(item.w_hum_lowest) && hum_low.push(Number(item.w_hum_lowest)) || hum_low.push(null);
                      !isNaN(item.w_hum_highest) && hum_high.push(Number(item.w_hum_highest)) || hum_high.push(null);

                      !isNaN(item.w_ps_average) && pres_avg.push(Number(item.w_ps_average)) || pres_avg.push(null);
                      !isNaN(item.w_ps_lowest) && pres_low.push(Number(item.w_ps_lowest)) || pres_low.push(null);
                      !isNaN(item.w_ps_highest) && pres_high.push(Number(item.w_ps_highest)) || pres_high.push(null);

                      !isNaN(item.w_ws_average) && wind_avg.push(Number(item.w_ws_average)) || wind_avg.push(null);
                      !isNaN(item.w_ws_lowest) && wind_low.push(Number(item.w_ws_lowest)) || wind_low.push(null);
                      !isNaN(item.w_ws_highest) && wind_high.push(Number(item.w_ws_highest)) || wind_high.push(null);
                    }
                  })

                  this.trendData = {
                    checkDate : checkDate,

                    watt_sum: watt_sum,
                    watt_low: watt_low,
                    watt_high: watt_high,

                    temp_avg: temp_avg,
                    temp_low: temp_low,
                    temp_high: temp_high,

                    hum_avg: hum_avg,
                    hum_low: hum_low,
                    hum_high: hum_high,

                    pres_avg: pres_avg,
                    pres_low: pres_low,
                    pres_high: pres_high,

                    wind_avg: wind_avg,
                    wind_low: wind_low,
                    wind_high: wind_high,
                  }

                }
              
                this.$store.commit("resMessage",res.message);
              } else {
                alert(res.message);
              }
              this.loading = false;
              this.$refs.farm.focus();
            }).catch( (err) => {  // API 오류 처리
                console.log("kepcoDataDailyView API 호출 오류",err)
                alert(err);
                this.loading = false;
            })
          }
    },
    async comboCompanies() {
      await Apis.comboCompanies({
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.companyItems = [];
            for (let i in res.data) {
            this.companyItems.push({
              code: res.data[i].company_cd,
              name: res.data[i].company_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboCompanies API 호출 오류",err)
        }
      ) 
    },
    changeCompany() {   // 하위 콤보 지우기;
      this.farm = {};
      this.building = {};
      // this.refreshList();
    },
    async comboFarms() {
      // console.log("콤보팜스",this.company || "");
      await Apis.comboFarms({
        company_cd: this.company && this.company.code || "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            // console.log("콤보res",res.data);
            this.farmItems = [];
            for (let i in res.data) {
            this.farmItems.push({
              company_cd: res.data[i].company_cd, 
              company_name: res.data[i].company_name, 
              code: res.data[i].farm_cd,
              name: res.data[i].farm_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboFarms API 호출 오류",err)
        }
      ) 
    },
    changeFarm() {      // 하위콤보 선택시 상위 콤보 동시 세팅, 하위콤보 지우기
      if (this.farm && this.farm.company_cd) {
        this.company = {code: this.farm.company_cd, name: this.farm.company_name};
      }
      // this.refreshList();
    },
    async comboBuildings() {
      await Apis.comboBuildings({
        company_cd:this.company &&  this.company.code || "",
        farm_cd: this.farm && this.farm.code || "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.buildingItems = [];
            for (let i in res.data) {
            this.buildingItems.push({
              company_cd: res.data[i].company_cd, 
              company_name: res.data[i].company_name, 
              farm_cd: res.data[i].farm_cd, 
              farm_name: res.data[i].farm_name, 
              code: res.data[i].building_cd,
              name: res.data[i].building_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboBuildings API 호출 오류",err)
        }
      ) 
    },
    changeBuilding() {      // 하위콤보 선택시 상위 콤보 동시 세팅, 하위콤보 지우기
      // changed = true;  
      if (this.building && this.building.company_cd) {
        this.company = {code: this.building.company_cd, name: this.building.company_name};
      }
      if (this.building && this.building.farm_cd) {
        this.farm = {code: this.building.farm_cd, name: this.building.farm_name};
      }
      if (this.building && this.building.code) {
      this.refreshList();
      }
    },
    goback() { 
      window.history.back();
    },
    detailPerTimes() {
      this.$router.push({
        name: "MonitorSensor",
        params: { 
          company: this.company,
          farm: this.farm,
          }
      });
    },

  }  // -----------methods 

}
</script>

<style lang="scss" scoped>
.v-text-field ::v-deep input {
    font-size: 0.9em;
}
.normal-btn{
  align-content: center !important;
  color:white !important;
  font-weight: bold !important;
  background-color: #3a4f3f !important;
}

.selectbox{
  width:30px;
  height:40px;
  margin-right:10px;
}
.show__btn{
  width:100px;
  height:40px !important;
  border-radius:20px;
  font-size:16px !important;
  letter-spacing: -2px;
  background-color:#eaeaea;
  color:#e4e4e4 !important;
  transition: all 0.3s ease 0s;
}

.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn-outlined) {
  border: none;
  border-radius: 45px;
  background: #e4e8d4;
  background-image: linear-gradient(to top, #e4e8d4 0%, #ddefdc 100%);
  color:#3a4f3f !important;
  box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.2);
  transition: all 0.3s ease 0s;
}

.theme--light .v-btn.v-btn--has-bg {
  color:#3a4f3a9f !important;
  font-weight:700;
  letter-spacing: -0.74px;
}


.items {
  color: #a3a1a1;
  font-size: 14px;
  // font-weight: bold;
  text-align: left;
  letter-spacing: -1.2px;
  }

  @media screen and (max-width: 768px) {
  .selectbox{
    width:100%;
    margin-top:10px;
  }
  v-slider {
    width:100%;
  }
  .change_btn{
    width:90%;
    justify-content: center;
  }
  .calendar{
    max-width:120px !important; 
  }
  .v-text-field ::v-deep input {
      font-size: 0.7em;
  }

  .normal-btn{
    width:60px !important;
    margin-left:15px !important; 
  }

}

</style>

